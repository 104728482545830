export default [
  {
    path: "/",
    text: "Kuka",
    id: "about"
  },
  {
    path: "/",
    text: "Hinnat",
    id: "packages"
  },
  {
    path: "/",
    text: "Ota yhteyttä",
    id: "contact"
  },
]
