import React from "react"
import { FaLinkedinIn, FaGithub,FaEnvelope } from "react-icons/fa"

export default [
  {
    icon: <FaLinkedinIn />,
    url: "https://www.linkedin.com/in/mikko-ker%C3%A4nen-9636913/",
    name: "LinkedIn",
  },
  {
    icon: <FaEnvelope />,
    url: "mailto:mikko@levelsafety.fi",
    name: "Email",
  },
]
