import React, { useState } from "react"
import links from "../../constants/links"
import styled from "styled-components"
import { Link, animateScroll as scroll } from "react-scroll"
import { slide as Menu } from 'react-burger-menu';

const menuStyles = {
  /* Position and sizing of burger button */
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    top: '36px',
    right: '36px',

  },
  /* Color/shape of burger icon bars */
  bmBurgerBars: {
    //background: '#373a47'
    background: '#ffffff',
  },
  /* Color/shape of burger icon bars on hover*/
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  /* Position and sizing of clickable cross button */
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  /* Color/shape of close button cross */
  bmCross: {
    background: '#bdc3c7'
  },

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  bmMenuWrap: {
    position: 'fixed',
    height: '100%'
  },
  /* General sidebar styles */
  bmMenu: {
    background: 'rgb(55 40 98)',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  /* Morph shape necessary with bubble or elastic */
  bmMorphShape: {
    fill: '#373a47'
  },
  /* Wrapper for item list */
  bmItemList: {
    color: '#b8b7ad',
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
  },
  /* Individual item */
  /*
  bmItem: {
    display: 'inline-block',
    textDecoration: 'none',
    textTransform: 'capitalize',
    color: '#fff',
    transition: '0.3s',
    padding: '0.8em 1rem',
    '&:hover': {
      cursor: 'pointer',
      color: '#e609b5',
    }
  },*/
  /* Styling of overlay */
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
}

const Navbar = ({pageWrapper}) => {
  const [isOpen, setNav] = useState(false)

  const toggleNav = () => {
    setNav(isOpen => !isOpen)
  }
  return (
    <NavWrapper>
      <div className="masthead flex-container">
        LevelSafety
      </div>
      <Menu styles={menuStyles} right pageWrapId={pageWrapper}>
        {links.map((item, index) => {
          return (
            <StyledLink key={index}
              activeClass="active"
              to={item.id}
              spy={true}
              smooth={true}
              duration={500}
              offset={-50}

            >
              {item.text}
            </StyledLink>
          );
        })}
      </Menu>
    </NavWrapper>
  )
}

const StyledLink = styled(Link)`

  font-size: 1.25rem;
  font-weight: 400;
  margin-left: 0;
  padding: 0.75rem 1rem;
  white-space: nowrap;
  a {
    text-decoration: none;
    text-transform: capitalize;
    color: #fff;
    transition: 0.3s;

    &.active {
      color: #e609b5;
    }
  }
  &:hover {
    cursor: pointer;
    color: #ffffff;
  }

`

const NavWrapper = styled.nav`
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  display: flex;
  padding: 1rem;
  box-sizing: border-box;

  .masthead {
    z-index: 3;
    width: 100%;
    justify-content: space-between;

    img {
      width: 90px;

      @media (min-width: 768px) {
        width: 100px;
      }

      @media (min-width: 1200px) {
        width: 120px;
      }
    }
  }

  .nav-links {
    display: table;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    text-align: center;
    background: linear-gradient(45deg, #060c21, #0d0139);
    margin: 0;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(100%);
    transition: 0.3s ease-in;
    list-style: none;
    padding-left: 0;

    li {
      display: table-cell;
      vertical-align: middle;
      list-style: none;
      font-size: 1.25rem;
      font-weight: 400;
      margin-left: 0;
      padding: 0.75rem 1rem;
      white-space: nowrap;
      a {
        text-decoration: none;
        text-transform: capitalize;
        color: #fff;
        transition: 0.3s;

        &.active {
          color: #e609b5;
        }
      }
      &:hover {
        cursor: pointer;
        a {
          color: #e609b5;
        }
      }
    }

    &.show-nav {
      transform: translateX(0%);
    }
  }

  .toggle-btn {
    width: 40px;
    height: 40px;
    padding: 5px;
    background-color: transparent;
    border: none;

    span {
      display: block;
      width: 30px;
      height: 2px;
      background-color: #fff;
      transition: 0.2s ease-in;

      &:nth-child(1) {
        transform: translateY(-5px);
      }

      &:nth-child(3) {
        transform: translateY(5px);
      }
    }

    &.toggle-btn-active {
      span {
        &:nth-child(1) {
          transform: translateY(2px) rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
          transform: translateX(-100%);
        }

        &:nth-child(3) {
          transform: translateY(-2px) rotate(-45deg);
        }
      }
    }
  }

  @media (min-width: 992px) {
    .masthead {
      flex-direction: column;
      justify-content: center;
    }

    .toggle-btn {
      display: none;
    }

    .nav-links {
      background: transparent;
      flex-direction: row;
      margin-left: auto;
      position: relative;
      transform: translateX(0);
      transition: none;

      li {
        margin-left: 1rem;
      }
    }
  }
`

export default Navbar
